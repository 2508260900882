import router from '@shaun/alpinejs-router';
import Alpine from 'alpinejs';
import tippy from "tippy.js";
import ui from "@alpinejs/ui";
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';
import Clipboard from "@ryangjchandler/alpine-clipboard";

import entries from "./viewports.json";

Alpine.plugin(focus);
Alpine.plugin(ui);
Alpine.plugin(persist);
Alpine.plugin(Clipboard);
router(Alpine);
window.viewports = entries;
// Magic: $tooltip
Alpine.magic('tooltip', el => message => {
    let instance = tippy(el, { content: message, trigger: 'manual' })

    instance.show()

    setTimeout(() => {
        instance.hide()

        setTimeout(() => instance.destroy(), 150)
    }, 2000)
})

// Directive: x-tooltip
Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression })
})

window.Alpine = Alpine
Alpine.start()
