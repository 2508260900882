import Alpine from 'alpinejs';
window.Alpine = Alpine
import tippy from "tippy.js";
import Clipboard from "@ryangjchandler/alpine-clipboard"

Alpine.plugin(Clipboard)

// Magic: $tooltip
Alpine.magic('tooltip', el => message => {
    let instance = tippy(el, { content: message, trigger: 'manual' })

    instance.show()

    setTimeout(() => {
        instance.hide()

        setTimeout(() => instance.destroy(), 150)
    }, 2000)
})

// Directive: x-tooltip
Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression })
})



async function poll(subject) {
    try {
        const response = await fetch("/s?c=" + subject)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        // console.log(response)
        let msgs = await response.json()
        // console.log(msgs)
        msgs.forEach((m) => {
            subject = m.s
            Alpine.store('s').handle(m)
        })
        // handle msg
        setTimeout(poll, 0, subject)
    } catch (error) {
        console.error("polling failed: ", error)
        Alpine.store('s').state = 'failed'
    }
}

document.addEventListener('alpine:init', () => {
    Alpine.store('s', {
        email: "",
        messages: [],
        ready: false,
        processing: false,
        progress: [],
        complete: "",
        state: "",
        handle(m) {
            if (m.a !== "k") {
                console.log("handling, a=", m.a, "e=", m.e, "m=", m.m, m)
            }
            switch (m.a) {
                case 'k':
                    break;
                case 'n':
                    if (m.e !== "") {
                        this.email = m.e
                    }
                    this.ready = true
                    break;
                case 'Read':
                case 'Write':
                case 'meta':
                case 'Connect':
                case 'TLS':
                    this.messages.push(m)
                    this.ready = false
                    break
                case 'ps':
                    this.state = 'processing'
                    this.processing = true
                    this.prog(m.p, m.m, m.a)
                    break
                case 'pe':
                case 'pf':
                    this.prog(m.p, m.m, m.a)
                    break
                case 'c':
                    this.complete = m.m
                    this.state = 'ready'
                    console.log("we're done, woohoo", m.m)
                    window.location.href = m.m
                    break
                default:
                    console.log('unexpected message', m)
                    break
            }
        },
        prog(name, msg, state) {
            for (let i = 0; i < this.progress.length; ++i) {
                if (this.progress[i].name === name) {
                    this.progress[i] = {
                        name: name,
                        m: msg,
                        a: state,
                    }
                    return
                }
            }
            console.log("Adding new process")
            this.progress.push({
                name: name,
                m: msg,
                a: state,
            })
        }
    })
    poll("")
})

Alpine.start()
