// src/url.js
var RouterURL = class {
  #url;
  constructor(foo, opts = {}) {
    this.#url = new URL(foo);
    this.base = opts.base ?? "";
  }
  set url(val) {
    this.#url = new URL(val);
  }
  get url() {
    return this.#url.href;
  }
  get hash() {
    return this.#url.hash;
  }
  get path() {
    return this.#url.pathname.replace(this.base, "");
  }
  get query() {
    return Object.fromEntries(
      new URLSearchParams(
        this.#url.href.indexOf("?") > -1 ? this.#url.href.split("?").pop() : ""
      )
    );
  }
  resolve(path, params, replace = false) {
    const l = this.#url.origin + this.base + path;
    const r = replace ? new URLSearchParams(params).toString() : new URLSearchParams({ ...this.query, ...params }).toString();
    this.url = l + (r ? "?" + r : "") + this.#url.hash;
    return this;
  }
};

// src/pattern.js
var URLPattern = class {
  static build(path) {
    if (path.indexOf(":") === -1) {
      return path;
    }
    const pattern = path.split("/").map((e) => {
      if (!e.startsWith(":")) {
        return e;
      }
      let field = e.substr(1);
      let fieldPattern = "[^/]+";
      const ef = field.match(/\((.+?)\)/);
      if (ef) {
        field = field.substr(0, field.indexOf("("));
        fieldPattern = ef[1];
      }
      return `(?<${field}>${fieldPattern})`;
    }).join("/");
    return new RegExp(`^${pattern}$`);
  }
  static match(path, pattern) {
    if (pattern instanceof RegExp) {
      const found = path.match(pattern);
      if (!found)
        return false;
      return { ...found.groups };
    }
    return path === pattern;
  }
  static is(path, pattern) {
    if (pattern instanceof RegExp) {
      return pattern.test(path);
    }
    return path === pattern;
  }
};

// src/router.js
var Router = class {
  #patterns = {};
  #cache = {};
  add(route) {
    this.#patterns = {
      ...this.#patterns,
      [route]: this.#cache[route] ?? URLPattern.build(route)
    };
  }
  match(target) {
    console.assert(target instanceof RouterURL);
    const path = target.path;
    for (const [route, pattern] of Object.entries(this.#patterns)) {
      const found = URLPattern.match(path, pattern);
      if (found) {
        return found === true ? {} : found;
      }
    }
    return false;
  }
  is(target, ...routes) {
    console.assert(target instanceof RouterURL);
    const path = target.path;
    for (const route of routes) {
      const pattern = this.#patterns[route] ?? this.#cache[route] ?? URLPattern.build(route);
      this.#cache[route] = pattern;
      if (URLPattern.is(path, pattern)) {
        return true;
      }
    }
    return false;
  }
  not(target, ...routes) {
    console.assert(target instanceof RouterURL);
    const path = target.path;
    for (const route of routes) {
      const pattern = this.#patterns[route] ?? this.#cache[route] ?? URLPattern.build(route);
      this.#cache[route] = pattern;
      if (URLPattern.is(path, pattern)) {
        return false;
      }
    }
    return true;
  }
  notfound(target) {
    console.assert(target instanceof RouterURL);
    const path = target.path;
    return Object.values(this.#patterns).findIndex(
      (e) => URLPattern.is(path, e)
    ) === -1;
  }
};

// src/index.js
function src_default(Alpine) {
  const router = new Router();
  const state = Alpine.reactive({
    base: "",
    href: location.href,
    path: "",
    hash: location.hash,
    query: {},
    params: {},
    loading: false
  });
  const route = {
    get path() {
      return state.path;
    },
    get query() {
      return state.query;
    },
    get hash() {
      return state.hash;
    },
    get params() {
      return state.params || {};
    },
    get loading() {
      return state.loading;
    },
    config(config = {}) {
      if (config.base && config.base.endsWith("/"))
        config.base = config.base.slice(0, -1);
      state.base = config.base ?? "";
    },
    push(...args) {
      return push(...args);
    },
    replace(...args) {
      return replace(...args);
    },
    resolve(query = {}) {
      return getTargetURL(state.href).resolve(state.path, query).url;
    },
    is(...paths) {
      return router.is(getTargetURL(state.href), ...paths);
    },
    not(...paths) {
      return router.not(getTargetURL(state.href), ...paths);
    },
    get notfound() {
      return router.notfound(getTargetURL(state.href));
    }
  };
  Alpine.$router = route;
  Alpine.magic("router", () => route);
  function getTargetURL(href) {
    return new RouterURL(href, { base: state.base });
  }
  function parse() {
    const url = getTargetURL(state.href);
    state.path = url.path;
    state.query = url.query;
    state.params = router.match(url);
    if (state.hash !== url.hash) {
      state.hash = url.hash;
      if (url.hash !== "") {
        Alpine.nextTick(() => {
          let el = document.getElementById(url.hash.substring(1));
          if (el) {
            el.scrollIntoView();
          }
        });
      }
    }
  }
  Alpine.effect(() => parse());
  Alpine.nextTick(() => {
    if (!state.base)
      parse();
  });
  window.addEventListener("popstate", () => state.href = location.href);
  function push(path, options = {}) {
    if (!path.startsWith(location.origin)) {
      path = location.origin + state.base + path;
    }
    if (location.href !== path) {
      history[options.replace ? "replaceState" : "pushState"]({}, "", path);
      state.href = path;
    }
  }
  function replace(path) {
    push(path, { replace: true });
  }
  const templateCaches = {};
  const inLoadProgress = {};
  const inMakeProgress = /* @__PURE__ */ new Set();
  Alpine.directive("route", (el, { modifiers, expression }, { effect, cleanup }) => {
    if (!modifiers.includes("notfound")) {
      router.add(expression);
    }
    const load = (url) => {
      if (inLoadProgress[url]) {
        inLoadProgress[url].then((html) => el.innerHTML = html);
      } else {
        inLoadProgress[url] = fetch(url).then((r) => r.text()).then((html) => {
          templateCaches[url] = html;
          el.innerHTML = html;
          return html;
        });
      }
      return inLoadProgress[url];
    };
    const tpl = el.getAttribute("template") ?? el.getAttribute("template.preload");
    let loading;
    if (el.hasAttribute("template.preload")) {
      loading = load(tpl).finally(() => loading = false);
    }
    function show() {
      if (el._x_currentIfEl)
        return el._x_currentIfEl;
      const make = () => {
        if (inMakeProgress.has(expression))
          return;
        inMakeProgress.add(expression);
        const clone = el.content.cloneNode(true).firstElementChild;
        Alpine.addScopeToNode(clone, {}, el);
        Alpine.mutateDom(() => {
          el.after(clone);
          Alpine.initTree(clone);
        });
        el._x_currentIfEl = clone;
        el._x_undoIf = () => {
          clone.remove();
          delete el._x_currentIfEl;
        };
        Alpine.nextTick(() => inMakeProgress.delete(expression));
      };
      if (el.content.firstElementChild) {
        make();
      } else if (tpl) {
        if (templateCaches[tpl]) {
          el.innerHTML = templateCaches[tpl];
          make();
        } else {
          if (loading) {
            loading.then(() => make());
          } else {
            state.loading = true;
            load(tpl).then(() => make()).finally(() => state.loading = false);
          }
        }
      } else {
        console.error(`Template for '${expression}' is missing`);
      }
    }
    function hide() {
      if (el._x_undoIf) {
        el._x_undoIf();
        delete el._x_undoIf;
      }
    }
    Alpine.nextTick(() => {
      effect(() => {
        const target = getTargetURL(state.href);
        const found = modifiers.includes("notfound") ? router.notfound(target) : router.is(target, expression);
        found ? show() : hide();
      });
    });
    cleanup(() => el._x_undoIf && el._x_undoIf());
  });
  Alpine.directive("link", (el, { modifiers, expression }, { evaluate, effect, cleanup }) => {
    const url = getTargetURL(el.href);
    el.href = url.resolve(url.path, url.query, true).url;
    function go(e) {
      e.preventDefault();
      push(el.href, { replace: modifiers.includes("replace") });
    }
    el.addEventListener("click", go);
    if (modifiers.includes("activity")) {
      const classes = expression ? evaluate(expression) : {};
      classes.active ??= "active";
      classes.exactActive ??= "exact-active";
      effect(() => {
        const [l, r] = [getTargetURL(el.href), getTargetURL(state.href)];
        el.classList.toggle(classes.active, r.path.startsWith(l.path));
        el.classList.toggle(classes.exactActive, l.path === r.path);
      });
    }
    cleanup(() => {
      el.removeEventListener("click", go);
    });
  });
}

// builds/module.js
var module_default = src_default;
export {
  module_default as default
};
